<template>
  <div class="c-tabs__panel c-main">
    <div class="o-layout">
      <div class="o-layout__item u-1-of-2-at-medium">
        <sceSelect v-model="round.formatId" name="format" label="competitionFormat"
                   inputName="formatField" :options="formats"
                   v-validate data-vv-rules="required"
                   :class="{'is-invalid': errors.has('format')}"
                   :config="{groups: true, localized: false, emptyLabel: 'none'}" />
      </div>
      <div class="o-layout__item u-1-of-2-at-medium o-flex o-flex--align-end">
        <div class="c-input--extra-info">
          {{getFormatDescription(round.formatId)}}
        </div>
      </div>
      <div class="o-layout__item u-1-of-2-at-medium">
        <sceSelect v-model="round.tieBreakId" name="tie" label="tieBreak"
                   :config="{localized: false, emptyLabel: 'none'}"
                   inputName="tieField" :options="[{id: null, name: 'none'}, ...tieBreaks]"/>
      </div>
    </div>
    <div class="o-layout">
      <div class="o-layout__item u-1-of-2-at-small">
        <sceSelect v-model="round.teamMethod" name="teamMethod"
                   :config="{ emptyLabel: 'none'}"
                   label="category.teamMethod" :options="$options.teamMethodOptions"/>
      </div>
      <div class="o-layout__item u-1-of-2-at-small">
        <sceNumberField v-if="round.teamMethod" v-model="round.teamCount" name="teamCount"
                        :label="'category.teamCount.' + round.teamMethod" min="0" max="100" step="1" />

      </div>

      <div class="o-layout__item u-1-of-2-at-medium" v-for="field of options" :key="field.config.label">
        <div v-if="field.config.inputType !== 'copy'" :is="'custom-field-' + field.config.inputType"
             v-model="field.value" :name="'round-' + field.config.label" :config="field.config"/>
      </div>

      <div class="o-layout__item u-1-of-2-at-medium" v-for="option of options" :key="option.label">
        <div class="c-input" v-if="option.inputType === 'select'">
          <label class="c-input__label" :for="'option-' + option.label">
            {{$t('categoryOption.' + option.label)}}
          </label>
          <select class="c-input__field c-input__field-select" v-model="option.value"
                  :id="'option-' + option.label">
            <option value="">
              {{$t('none')}}
            </option>
            <option v-for="ov of option.values" :value="ov.value" :key="ov.value">
              {{$t('categoryOption.' + ov.label)}}
            </option>
          </select>
        </div>
        <sceNumberField v-if="option.inputType === 'number'" v-model.number="option.value"
                        :name="'option-' + option.label" :label="'categoryOption.' + option.label"
                        :min="option.min" :max="option.max" :step="option.step" />
      </div>
    </div>
    <div class="o-layout" v-if="discipline.allowTrackSelection">
      <div class="o-layout__item">
        <h4 class="c-title c-title--page-section">{{$t('categoryRound.trackSelection')}}</h4>
      </div>
      <div class="o-layout__item">
        <sceCheckBox v-model="round.trackSelection.enable" :name="'trackSelection-enable-' + round.index"
                     label="categoryRound.trackSelection.enable" />
      </div>
      <div v-if="round.trackSelection.enable" class="o-layout__item u-1-of-2-at-small">
        <sceNumberField v-model="round.trackSelection.minimumScore" :name="'minimumScore-' + round.index"
                        label="categoryRound.trackSelection.minimumScore" min="0" max="99999" step="0.001"
                        v-validate data-vv-rules="numeric|min_value:0|max_value:99999" />
      </div>
      <div v-if="round.trackSelection.enable" class="o-layout__item u-1-of-2-at-small">
        <sceSelect v-model="round.trackSelection.requirementsCheck" :name="'requirementsCheck-' + round.index"
                   label="categoryRound.trackSelection.requirementsCheck" :options="roundExerciseTypes"
                   :config="{emptyLabel: 'none'}"/>
      </div>

    </div>
    <template v-if="round.index > 0">
      <h4 class="c-title c-title--page-section">{{$t('final_result')}}</h4>
      <div class="o-layout">
        <div v-for="(rt, type) of results" :key="'results-' + type"
             class="o-layout__item u-1-of-2-at-medium">
          <sceSelect v-model="rt.result" :name="'result-'+ round.index + '-'+ type" :label="'rankingType.' + type"
                     :options="$options.roundResultOptions" v-validate data-rules="required"
                     :class="{'is-invalid': errors.has('result-'+ round.index + '-'+ type)}" />
        </div>
      </div>

      <h4 class="c-title c-title--page-section">{{$t('categoryRound.selectionCriteria')}}</h4>
      <div class="o-layout">
        <div class="o-layout__item u-1-of-4-at-small">
          <sceNumberField v-model.number="round.selectionCriteria.maxCount" min="0" max="1000" step="1"
                          label="categoryRound.selectionCriteria.count" :name="'selection-count-'+ round.index"
                          v-validate data-vv-rules="required|numeric|min_value:0|max_value:1000"
                          :class="{'is-invalid': errors.has('selection-count-'+ round.index)}" />
        </div>
        <div class="o-layout__item u-1-of-4-at-small">
          <sceNumberField v-model.number="round.selectionCriteria.reserveCount" min="0" max="1000" step="1"
                          label="categoryRound.selectionCriteria.reserves" :name="'selection-reserves-'+ round.index"
                          v-validate data-vv-rules="required|numeric|min_value:0|max_value:1000"
                          :class="{'is-invalid': errors.has('selection-reserves-'+ round.index)}" />
        </div>
        <div class="o-layout__item u-1-of-4-at-small">
          <sceNumberField v-model.number="round.selectionCriteria.minScore" min="0" max="9999" step="0.001"
                          label="categoryRound.selectionCriteria.minScore" :name="'selection-min-'+ round.index"
                          v-validate data-vv-rules="required|decimal|min_value:0|max_value:9999"
                          :class="{'is-invalid': errors.has('selection-min-'+ round.index)}" />
        </div>
        <div class="o-layout__item u-1-of-4-at-small">
          <sceSelect v-model.number="round.selectionCriteria.clubCount" :name="'selection-clubCount-'+ round.index"
                     label="categoryRound.selectionCriteria.clubMax"
                     :options="[{id: 0, name: $t('unlimited')}, {id: 1, name: '1'}, {id: 2, name: '2'}, {id: 3, name: '3'}]" />
        </div>
      </div>
    </template>
    <div class="o-layout">
      <div class="o-layout__item u-1-of-2-at-small">
        <h4 class="c-title c-title--page-section">{{$t('categoryRound.singleAwards')}}</h4>

        <div class="o-layout">
          <div class="o-layout__item">
            <sceSelect v-model="round.singleAwardsType" name="singleAwardsType"
                       :options="$options.awardTypes" :config="{emptyLabel: 'none'}" />
          </div>
        </div>
        <categoryAwards v-if="round.singleAwardsType" namePrefix="singleAwards"
                        v-model="round.singleAwards" />
      </div>

      <div class="o-layout__item u-1-of-2-at-small" v-if="discipline.team">
        <h4 class="c-title c-title--page-section">{{$t('categoryRound.teamAwards')}}</h4>

        <div class="o-layout">
          <div class="o-layout__item">
            <sceSelect v-model="round.teamAwardsType" name="teamAwardsType"
                       :options="$options.awardTypes" :config="{emptyLabel: 'none'}" />
          </div>
        </div>
        <categoryAwards v-if="round.teamAwardsType" namePrefix="teamAwards"
                        v-model="round.teamAwards" />
      </div>
      <div class="o-layout__item u-1-of-2-at-small"
           v-if="discipline.exerciseTiming === 'category' || discipline.exerciseTiming === 'exercise'">
        <h4 class="c-title c-title--page-section">{{$t('category.exerciseTiming')}}</h4>

        <sceNumberField v-if="discipline.exerciseTiming === 'category'"
                        v-model.number="round.exerciseTime" name="exerciseTime"
                        label="session.schedule.exerciseTime" min="0" max="1200" step="1" />

        <div class="o-layout" v-if="discipline.exerciseTiming === 'exercise' && round.formatId">
          <div v-for="exercise of round.exerciseTiming" :key="exercise.type" class="o-layout__item">
            <sceNumberField v-model.number="exercise.time" :name="'exerciseTime:' + exercise.type"
                            :label="'exercise.type.'+exercise.type" min="0" max="1200" step="1" />
          </div>
        </div>
        <p v-if="discipline.exerciseTiming === 'exercise' && ! round.formatId">
          {{ $t('category.exerciseTiming.noFormat') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// import cloneDeep from 'lodash/cloneDeep'
import filter from "lodash/filter"
import find from "lodash/find"
import map from "lodash/map"
import merge from "lodash/merge"
import sortBy from "lodash/sortBy"

import categoryLib from "@/lib/category"
import customFieldsLib from "@/lib/customFields"
import options from "@/lib/options";
import forOwn from "lodash/forOwn";

export default {
  name: 'import-template-round',
  components: {
    categoryAwards: require('client/components/models/awards.vue').default,
  },
  props: ['value'],
  ...options.category,
  data: function () {
    return {
      round: {
        formatId: null,
        tieBreakId: null,
        teamMethod: 'event',
        teamCount: 3,
        exerciseTime: null,
        exerciseTiming: [],
        singleAwardsType: null,
        singleAwards: [],
        teamAwardsType: null,
        teamAwards: [],
        selectionCriteria: {
          maxCount: 0,
          reserveCount: 0,
          clubCount: 0,
          minScore: 0,
        },
        trackSelection: {
          enable: false,
          minimumScore: 0,
          requirementsCheck: null,
        },
      },
      roundExerciseTypes: [],
      options: {},
      results: {
        "AA": {"result": "reset"},
        "TEAM-AA": {"result": "reset"},
        "EVENTS": {"result": "reset"},
        "TEAM-EVENTS": {"result": "reset"},
      },
    }
  },
  computed: {
    planningDefault: function () {
      return find(this.$store.state.planningDefaults.items,
          item => item.id === this.$route.params.planningDefaultId)
    },
    discipline: function () {
      return find(this.$store.state.disciplines.items,
          i => i.id === this.planningDefault.disciplineId)
    },
    formats: function () {
      let formats = []

      const activeFormats = sortBy(filter(this.$store.state.importTemplates.formats,
          f => !f.deprecated), ['index'])
      if (activeFormats.length) {
        formats.push({
          name: this.$t('active'),
          elements: activeFormats,
        })
      }

      const inActiveFormats = sortBy(filter(this.$store.state.importTemplates.formats,
          f => f.deprecated), ['index'])
      if (inActiveFormats.length) {
        formats.push({
          name: this.$t('inactive'),
          elements: inActiveFormats,
        })
      }
      return formats
    },
    tieBreaks: function () {
      return sortBy(this.$store.state.importTemplates.tieBreaks, 'index')
    },
  },
  created: function () {
    if (this.value.results) {
      forOwn(this.value.results, (result, type) => {
        console.log(type, result)
        this.results[type].result = result
      })
    }
    this.round = merge(this.round, this.value)
  },
  watch: {
    round: {
      deep: true,
      handler: function () {
        this.emitValue()
      }
    },
    options: {
      deep: true,
      handler: function () {
        this.emitValue()
      }
    },
    results: {
      deep: true,
      handler: function () {
        this.emitValue()
      }
    },
    'round.formatId': {
      immediate: true,
      handler: function() {
        this.setExerciseTimes()
        this.setOptions()
        this.setRoundExerciseTypes()
      },
    },
  },
  methods: {
    setOptions: function() {
      let options = []

      let config = {}
      if (this.round.config) {
        config = this.round.config
      }
      const format = find(this.$store.state.eventDiscipline.formats, i => i.id === this.round.formatId)

      if (this.discipline.options) {
        options = this.discipline.options
      }
      if (format?.options) {
        options = options.concat(format.options)
      }

      this.options = customFieldsLib.getFields(options, config, format)
    },
    emitValue: function () {
      this.round.config = customFieldsLib.getValues(this.options)
      this.round.results = {}
      forOwn(this.results, (val, type) => {
        this.round.results[type] = val.result
      })
      this.$emit('input', this.round)
    },
    getFormatDescription: function(formatId) {
      const format = find(this.$store.state.importTemplates.formats, item => item.id === formatId)
      return format ? format.description : this.$t('category.skipRound.description')
    },
    setExerciseTimes: function() {
      if (this.round.formatId && this.discipline.exerciseTiming === 'exercise') {
        const format = this.$store.state.importTemplates.formats.find(f => f.id === this.round.formatId)
        const exercises = categoryLib.getExerciseTypes(format)
        const prevExerciseTiming = this.round.exerciseTiming || []
        this.round.exerciseTiming = map(exercises, exerciseTypeId => {
          const time = find(prevExerciseTiming, time => time.type === exerciseTypeId)
          return time ? time : {type: exerciseTypeId, time: undefined}
        })
      }
    },
    setRoundExerciseTypes: function() {
      let exerciseTypes = []
      if (this.round.formatId) {
        const format = find(this.$store.state.importTemplates.formats, i => i.id === this.round.formatId)
        exerciseTypes = categoryLib.getExerciseTypes(format)
      }
      this.roundExerciseTypes = exerciseTypes.map(e => ({
        id: e,
        name: this.$t('exercise.type.' + e)
      }))
    },
  },
}
</script>

<style scoped>
</style>
