'use strict';

import store from 'client/store';
import Vue from 'vue';

import categoryLib from 'client/lib/category'
import panelHandler from 'client/lib/panelHandler.js';
import participantLib from 'client/lib/participant.js';
import rounds from 'client/lib/rounds'
import scoreHandler from 'lib/scoreHandler.js';

import clone from "lodash/clone";
import concat from "lodash/concat";
import filter from "lodash/filter";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import forEach from "lodash/forEach";
import map from "lodash/map";
import merge from "lodash/merge";
import sortBy from "lodash/sortBy";
import unionBy from "lodash/unionBy";

import {min} from 'mathjs';
import numeral from 'numeral';
import moment from "moment";

export default {
  getRanking: function(categoryId, roundIndex, type, subType, region = null, exerciseTypeId = null) {
    return find(store.state.rankings.items, ranking => {
      return ranking.categoryId === categoryId && ranking.roundIndex === roundIndex && ranking.rankingType === type
        && ranking.includeType === subType && (subType !== 'regional' || ranking.region === region)
        && (! this.hasEvent(ranking) || ranking.exerciseTypeId === exerciseTypeId);
    });
  },

  exerciseConfig: function(discipline, format, exerciseType) {
    const formatExerciseType = find(format.exercises, item => {
      return item.exerciseTypeId === exerciseType.id
    })

    let configuration = {}

    merge(configuration,
      discipline.config,
      exerciseType.config,
      format.config,
      formatExerciseType.config)

    return configuration
  },

  hasEvent: function(ranking) {
    return ranking.rankingType === 'EVENTS' || ranking.rankingType === 'TEAM-EVENTS'
  },

  hasTeam: function(ranking) {
    return ranking.rankingType === 'TEAM-AA' || ranking.rankingType === 'TEAM-EVENTS'
  },

  getIndex: function(ranking) {
    const category = find(store.state.categories.items, i => i.id === ranking.categoryId)
    const round = rounds.getRound(ranking.roundIndex)
    const catIndex = numeral(category.index).format('0000');
    const roundIndex = numeral(round.index).format('00');

    let type = 'AA';
    let exercise = '000';
    switch (ranking.rankingType) {
      case 'TEAM-AA': {
        type = 'AT';
        break;
      }
      case 'EVENTS': {
        type = 'EI';
        const exerciseType = find(store.state.eventDiscipline.exerciseTypes, (i) => i.id === ranking.exerciseTypeId);
        exercise = numeral(exerciseType.index + 1).format('000');
        break;
      }
      case 'TEAM-EVENTS': {
        type = 'ET';
        const exerciseType = find(store.state.eventDiscipline.exerciseTypes, (i) => i.id === ranking.exerciseTypeId);
        exercise = numeral(exerciseType.index + 1).format('000');
        break;
      }
    }

    let subType = '00'
    let region = '00';
    switch (ranking.includeType) {
      case 'open':
        subType = '01';
        break;
      case 'regional': {
        subType = '02';
        const event = store.state.events.current
        const country = find(store.state.events.countries, item => event.countryId === item.id);
        region = numeral(findIndex(country.regions, item => ranking.region === item.id) + 1).format('00');
        break;
      }
    }

    return catIndex + '-' + roundIndex + '-' + type + subType + exercise + region;
  },

  sortRankings: function(rankings) {
    return map(sortBy(map(rankings, ranking => {
      return {
        ranking: ranking,
        index: this.getIndex(ranking),
      };
    }), 'index'), i => i.ranking);
  },

  getTitle: function(ranking, category, round) {
    let name = '';

    const categoryRounds = filter(category.rounds, item => {
      return item.formatId;
    });

    const roundRanking = round.rankings.find(r => r.rankingType === ranking.rankingType)
    if (categoryRounds.length > 1 && (round.index > 0 || roundRanking.nextRound >= 0)) {
      name = round.name + ' ';
    }

    if (this.hasEvent(ranking)) {
      if (ranking.rankingType === 'TEAM-EVENTS') {
        name += ' ' + Vue.i18n.t('team')
      }

      name += ' ' + Vue.i18n.t('exercise.type.' + ranking.exerciseTypeId);
    } else {
      name += Vue.i18n.t('rankingType.' + ranking.rankingType);
    }

    switch (ranking.includeType) {
      case 'open':
        name += ' ' + Vue.i18n.t('open');
        break;
      case 'regional': {
        const event = store.state.events.current
        const country = find(store.state.events.countries, (item) => {
          return event.countryId === item.id
        });
        const region = find(country.regions, (item) => {
          return ranking.region === item.id
        });
        name += ' ' + region.name;
      }
    }
    return name;
  },

  getPreviousCategoryRound: function(roundIndex, type, category) {
    const round = rounds.getRound(roundIndex)

    const prevRound = rounds.prevRound(round, type)

    if (prevRound >= 0) {
      return category.rounds.find(cr => cr.roundIndex === prevRound)
    }

    return null
  },

  getRoundExercises: function(ranking, category, categoryRound, eventDiscipline, discipline, addTotal) {
    let rankingExercises = []
    let result = 'reset'
    if (categoryRound.results && categoryRound.results[ranking.rankingType]) {
      result = categoryRound.results[ranking.rankingType]
    }
    else if (categoryRound.result) {
      result = categoryRound.result
    }

    // check if we need to add previous round results:
    if (eventDiscipline.finalsReports === 'combined' || result === 'add') {
      const prevCategoryRound = this.getPreviousCategoryRound(ranking.roundIndex, ranking.rankingType, category)

      if (prevCategoryRound) {
        const prevRanking = this.getRanking(
          category.id, prevCategoryRound.roundIndex, ranking.rankingType, ranking.includeType, ranking.region, ranking.exerciseTypeId)
        const addTotal = categoryRound.result !== 'add' && ! this.hasEvent(ranking)
        rankingExercises = this.getRoundExercises(prevRanking, category, prevCategoryRound, eventDiscipline, discipline, addTotal)
      }
    }

    let roundExercises = categoryLib.getCategoryExerciseTypes(category.id, ranking.roundIndex)
    if (this.hasEvent(ranking)) {
      roundExercises = filter(roundExercises, item => item === ranking.exerciseTypeId)
    }

    const format = find(store.state.eventDiscipline.formats, item => item.id === categoryRound.formatId)

    // add configurations
    roundExercises = map(roundExercises, exerciseTypeId => {
      const exerciseType = find(store.state.eventDiscipline.exerciseTypes, type => type.id === exerciseTypeId)
      const config = this.exerciseConfig(discipline, format, exerciseType)

      if (config.consolidatePasses || config['final.' + ranking.rankingType] === 'first') {
        config.passes = 1
      }

      return {
        exerciseTypeId,
        roundIndex: ranking.roundIndex,
        config,
      }
    })

    rankingExercises.push({
      roundIndex: ranking.roundIndex,
      exercises: roundExercises,
      addTotal,
    })

    return rankingExercises
  },

  getRankingExercises: function(ranking, category, discipline) {
    const eventDiscipline = store.state.eventDisciplines.items.find(i => i.id === category.eventDisciplineId)
    const categoryRound = find(category.rounds, i => i.roundIndex === ranking.roundIndex)

    return this.getRoundExercises(ranking, category, categoryRound, eventDiscipline, discipline, false)
  },

  getExercise: function (participationId, exerciseType) {
    return find(store.state.exercises.items, exercise => {
      return exercise.exerciseTypeId === exerciseType.exerciseTypeId &&
        exercise.roundIndex === exerciseType.roundIndex && exercise.participationId === participationId;
    });
  },

  assembleRankingItem: function(ranking, rankingItem, rankingExercises, filter) {
    const participation = participantLib.getParticipation(rankingItem.participationId)
    const participant = participantLib.getParticipant(participation)
    const clubName = participantLib.getClubName(participation, participant, filter.addCountry)

    let exercises = []
    const format = '0.000'

    const formats = ['0', '0.0', '0.00', '0.000', '0.0000']

    if (filter.judgeScores) {
      let first = true
      forEach(rankingExercises, roundExercises => {
        forEach(roundExercises.exercises, rankingExercise => {
          const passes = rankingExercise.config.passes ? rankingExercise.config.passes : 1
          const exercise = this.getExercise(rankingItem.participationId, rankingExercise)

          let exerciseType = rankingExercise.exerciseTypeId

          const options =
            participantLib.getExerciseOptions(participation, rankingExercise.roundIndex, rankingExercise.exerciseTypeId)
          if (options.exerciseTypeId) {
            exerciseType = options.exerciseTypeId
          }
          const label = Vue.i18n.t('exercise.type.' + exerciseType)

          for (let pass = 1; pass <= passes; pass++) {
            let exerciseData = {
              label: label + (passes > 1 ? ' ' + pass : ''),
              first,
            }
            first = false
            if (exercise) {
              exerciseData.status = exercise.status
            } else {
              exerciseData.status = 'missing'
            }

            exerciseData.values = filter.scoreFields.map(f => {
              if (! exercise) return ''

              f.properties.leg = pass
              const value = scoreHandler.exerciseGetValue(exercise, f.properties)

              if (value) {
                return {
                  value: numeral(value.publicValue).format(formats[f.precision]),
                  width: f.width,
                }
              }
            })

            exercises.push(exerciseData)
          }
        })
      })
    }
    else {
      forEach(rankingExercises, roundExercises => {
        forEach(roundExercises.exercises, rankingExercise => {
          const exercise = rankingItem.exercises.find(e =>
            e.exerciseTypeId === rankingExercise.exerciseTypeId
            && rankingExercise.roundIndex === e.roundIndex)

          if (participation.participantType === 'team') {
            let exerciseData = {
              status: 'total',
              values: exercise.passes,
              ranking: exercise.ranking,
              total: exercise.total,
            }

            exercises.push(exerciseData)
          }
          else {
            const passes = rankingExercise.config.passes ? rankingExercise.config.passes : 1

            for (let pass = 1; pass <= passes; pass++) {
              let exerciseData = {}
              if (exercise !== undefined) {
                exerciseData.status = exercise.status
                exerciseData.summary = exercise.passes && exercise.passes[pass - 1] ? exercise.passes[pass - 1] : {}
                const options =
                  participantLib.getExerciseOptions(participation, exercise.roundIndex, exercise.exerciseTypeId)
                if (options.exerciseTypeId) {
                  exerciseData.choice = Vue.i18n.t('exercise.type.' + options.exerciseTypeId)
                }
                if (passes === 1) {
                  exerciseData.ranking = exercise.ranking
                }
              } else {
                exerciseData.status = 'missing'
              }
              exercises.push(exerciseData)
            }
            if (passes > 1 && ranking.rankingType !== 'EVENTS') {
              if (exercise) {
                exercises.push({
                  status: 'total',
                  summary: {
                    total: (exercise.total !== undefined) ? numeral(exercise.total).format(format) : '',
                    values: []
                  },
                })
              }
              else {
                exercises.push({
                  status: 'total',
                  summary: {
                    total: null,
                    values: []
                  },
                })
              }
            }
          }
        })

        if (roundExercises.addTotal) {
          const total = rankingItem.roundResults?.find(rs => rs.roundIndex === roundExercises.roundIndex)
          exercises.push({
            status: 'total',
            values: {},
            summary: {
              values: {},
              total: total ? total.result : '',
            },
            total: total ? total.result : '',
          })
        }
      })
    }

    let aaResults = null
    if (participation.participantType === 'team' && ranking.teamMethod === 'AA') {
      const totals = rankingItem.exercises.find(e => e.exerciseTypeId === 'totals')
      if (totals) {
        aaResults = totals.passes.map(p => p.total)
      }
    }

    const names = participantLib.getParticipantNames(participation)

    let rank = rankingItem.ranking
    let award = null

    switch (filter.awards) {
      case 'medals':
        switch (rank) {
          case 1:
            award = Vue.i18n.t('medal.gold')
            break
          case 2:
            award = Vue.i18n.t('medal.silver')
            break
          case 3:
            award = Vue.i18n.t('medal.bronze')
            break
        }
        break
      default:
        if (rankingItem.award) {
          award = rankingItem.award.label
        }
    }

    if (rank <= 0) {
      if (rankingItem.annotations.guest) rank = 'hc'
      else rank = '-'
    }

    let data = {
      id: participation.id,
      ranking: rank,
      total: rankingItem.result,
      award,
      annotations: rankingItem.annotations,
      bib: participation.bib,
      clubName,
      names,
      exercises,
      aaResults,
    }

    return data
  },

  sortByAward: function(rankingItems, filter = {}) {
    let sortFunction

    const order = filter.awardOrder
    console.log('awards order', order)

    switch (order) {
      case 'name':
        sortFunction = (i1, i2) => {
          const participation1 = participantLib.getParticipation(i1.participationId)
          const name1 = participantLib.getName(participation1)
          const participation2 = participantLib.getParticipation(i2.participationId)
          const name2 = participantLib.getName(participation2)

          return name1.localeCompare(name2, Vue.i18n.locale, {sensitivity: 'base'})
        }
        break
      case 'clubName':
        sortFunction = (i1, i2) => {
          const participation1 = participantLib.getParticipation(i1.participationId)
          const club1 = participantLib.getParticipationClub(participation1).name
          const participation2 = participantLib.getParticipation(i2.participationId)
          const club2 = participantLib.getParticipationClub(participation2).name

          const clubCompare = club1.localeCompare(club2, Vue.i18n.locale, {sensitivity: 'base'})
          if (clubCompare !== 0) return clubCompare

          const name1 = participantLib.getName(participation1)
          const name2 = participantLib.getName(participation2)

          return name1.localeCompare(name2, Vue.i18n.locale, {sensitivity: 'base'})
        }
        break
      case 'bib':
        sortFunction = (i1, i2) => {
          const bib1 = participantLib.getParticipation(i1.participationId).bib
          const bib2 = participantLib.getParticipation(i2.participationId).bib
          return bib1 - bib2
        }
        break
      default:
        sortFunction = (i1, i2) => {
          const award1 = (i1.award?.index !== undefined) ? i1.award.index : 999
          const award2 = (i2.award?.index !== undefined) ? i2.award.index : 999
          const awardCompare = award1 - award2
          if (awardCompare !== 0) return awardCompare

          const bib1 = participantLib.getParticipation(i1.participationId).bib
          const bib2 = participantLib.getParticipation(i2.participationId).bib
          return bib1 - bib2
        }
        break
    }

    return rankingItems.toSorted(sortFunction)
  },

  prepareExport: function(ranking, category, round, discipline, filter, judgeScores = false) {
    const rankingExercises = this.getRankingExercises(ranking, category, discipline);
    const panelTypes = store.state.eventDiscipline.panelTypes;

    let dataSet = [];
    let mainFields = [
      {label: Vue.i18n.t('category'), value: 'category'},
      {label: Vue.i18n.t('title'), value: 'title'},
      {label: Vue.i18n.t('ranking'), value: 'ranking'},
      {label: Vue.i18n.t('bib'), value: 'bib'},
      {label: Vue.i18n.t('name'), value: 'name'},
      {label: Vue.i18n.t('club'), value: 'club'},
    ];

    switch (category.participantType) {
      case 'clubMember':
        mainFields.push({label: Vue.i18n.t('participantId'), value: 'participantId_1'},);
        break;
      case 'group':
        if (category.groupSize && category.groupSize.max > 0) {
          for (let i = 1; i <= category.groupSize.max; i++) {
            mainFields.push({label: Vue.i18n.t('participantId') + ' ' + i, value: 'participantId_' + i});
          }
        }
        break;
    }

    mainFields.push({label: Vue.i18n.t('subDivision'), value: 'subDivision'})

    let exercises = [];

    forEach(rankingExercises, roundExercises => {
      forEach(roundExercises.exercises, item => {
        let fields = []
        const passes = item.config.passes ? item.config.passes : 1

        if (judgeScores) {
          const panelType = find(panelTypes, panelType => {
            return panelType.id === item.config.panelTypeId
          })
          for (let pass = 1; pass <= passes; pass++) {
            fields = concat(fields, panelHandler.calculateCsvFields(item.exerciseTypeId, item.config, panelType, pass))
          }
        } else {
          for (let pass = 1; pass <= passes; pass++) {
            fields.push({label: Vue.i18n.t('total') + (passes > 1 ? ' ' + pass : ''), value: 'total.' + pass})
          }
        }

        exercises.push({
          exerciseTypeId: item.exerciseTypeId,
          index: item.index,
          fields: fields,
        })
      })
    })

    if (ranking.rankingType !== 'EVENTS') {
      mainFields.push('total')
    }

    mainFields.push({label: Vue.i18n.t('award'), value: 'award'})

    const categoryRound = category.rounds.find(cr => cr.roundIndex === round.index)
    if (categoryRound.trackSelection?.enable) {
      mainFields.push({label: Vue.i18n.t('selection'), value: 'annotations.selection'})
    }

    const title = this.getTitle(ranking, category, round)

    let items = this.filterRankingItems(ranking, filter)
    forEach(items, rankingItem => {
      const participation = participantLib.getParticipation(rankingItem.participationId)
      const participant = participantLib.getParticipant(participation)
      const participationRound = participation.rounds[round.index]
      const club = participantLib.getClub(participant)

      let data = {
        category: category.name,
        title: title,
        ranking: rankingItem.ranking,
        bib: participation.bib,
        name: participantLib.getName(participation),
        club: club.name,
        subDivision: participationRound.subDivision,
        annotations: rankingItem.annotations,
      }

      switch (participation.participantType) {
        case 'clubMember':
          data.participantId_1 = participant.accessId
          break
        case 'group':
          if (category.groupSize && category.groupSize.max > 0) {
            const members = participantLib.getMembers(participant, 'group')
            const size = min(category.groupSize.max, members.length)
            for (let i = 0; i < size; i++) {
              data['participantId_' + (i+1)] = members[i].accessId
            }
          }
          break
      }

      forEach(rankingExercises, roundExercises => {
        forEach(roundExercises.exercises, item => {
          const exercise = this.getExercise(rankingItem.participationId, item)
          if (exercise && exercise.summary) {
            if (judgeScores) {
              data[exercise.exerciseTypeId] = scoreHandler.extractCsvScores(exercise)
            } else {
              const passes = item.config.passes ? item.config.passes : 1
              let total = {}
              for (let pass = 1; pass <= passes; pass++) {
                if (exercise.summary.passes.length >= pass) {
                  total[pass] = exercise.summary.passes[pass - 1].total
                }
              }

              data[exercise.exerciseTypeId] = {
                total: total,
              }
            }
          }
        })
      })

      if (ranking.rankingType !== 'EVENTS') {
        data['total'] = rankingItem.result
      }
      if (rankingItem.award) {
        data['award'] = rankingItem.award.label
      }

      dataSet.push(data)
    });

    return {
      fields: {
        main: mainFields,
        exercises: exercises,
      },
      data: dataSet,
    }
  },

  mergeExportFields: function(fields1, fields2) {
    const main =  unionBy(fields1.main, fields2.main, 'value')
    const exercises = unionBy(fields1.exercises, fields2.exercises, 'exerciseTypeId')
    return {
      main,
      exercises,
    }
  },

  flattenExportFields: function(fields) {
    let result = fields.main;

    let exercises = sortBy(fields.exercises, 'index');

    forEach(exercises, exercise => {
      const exerciseName = Vue.i18n.t('exercise.type.' + exercise.exerciseTypeId);
      result = concat(result, map(exercise.fields, field => {
        return {
          label: exerciseName + ' ' + field.label,
          value: exercise.exerciseTypeId + '.' + field.value,
        }
      }));
    });

    return result;
  },

  filterRankingItems: function(ranking, filter) {
    let items = ranking.items;
    switch (filter.awards) {
      case 'awards':
        items = this.sortByAward(items, filter)
        break
      case 'medals':
        items = items.filter(i => (1 <= i.ranking && i.ranking <= 3))
        break
    }

    if (filter.subDivision) {
      items = items.filter(item => {
        const participation = participantLib.getParticipation(item.participationId);
        const pRound = find(participation.rounds, item => {
          return item.roundIndex === ranking.roundIndex
        });
        return (parseInt(pRound.subDivision) === filter.subDivision);
      });
    }

    if (filter.set) {
      items = items.filter(item => {
        const blockParticipation = find(store.state.blockParticipations.items, itemBP => {
          return itemBP.participationId === item.participationId;
        });

        if (!blockParticipation) {
          return false;
        }

        const block = find(store.state.blocks.items, item => {
          return item.id === blockParticipation.blockId;
        });

        return block.set === filter.set;
      });
    }

    if (filter.clubId) {
      items = items.filter(item => {
        const participation = participantLib.getParticipation(item.participationId);
        const participant = participantLib.getParticipant(participation);
        return participant.clubId === filter.clubId;
      });
    }

    return items
  },

  preparePrint: function(ranking, category, round, discipline, filter) {
    const rankingExercises = filter.awards? [] : this.getRankingExercises(ranking, category, discipline)
    const eventDiscipline = store.state.eventDisciplines.items.find(ed => ed.id === category.eventDisciplineId)
    filter.awardOrder = eventDiscipline.awardOrder

    let exercises = []
    let addTotal = false

    if (filter.judgeScores) {
      const config = rankingExercises[0].exercises[0].config
      const panelType = store.state.eventDiscipline.panelTypes.find(p => p.id === config.panelTypeId)
      if (config.passes > 1) addTotal = true

      const fields = panelHandler.getPanelFields(panelType, rankingExercises[0].exercises[0].config, filter.judgeScores)
      exercises = fields.map(f => f.label)
      exercises = fields.map(f => ({
        label: f.label,
        width: f.width,
      }))

      filter.scoreFields = fields
    } else {
      forEach(rankingExercises, roundExercises => {
        forEach(roundExercises.exercises, (rankingExercise, i) => {
          let exerciseName = Vue.i18n.t('exercise.type.' + rankingExercise.exerciseTypeId);
          if (discipline.report === 'tiny') {
            exerciseName = numeral(i+1).format('0');
          }
          const passes = rankingExercise.config.passes ? rankingExercise.config.passes : 1;
          if (passes === 1 || this.hasTeam(ranking)) {
            exercises.push(exerciseName);
          } else {
            for (let pass = 1; pass <= passes; pass++) {
              exercises.push(exerciseName + ' ' + pass);
            }
            if (ranking.rankingType === 'AA') {
              exercises.push(exerciseName + ' ' + Vue.i18n.t('total'));
            }
          }
        })

        if (roundExercises.addTotal) {
          const round = rounds.getRound(roundExercises.roundIndex)
          exercises.push(round.name + ' ' + Vue.i18n.t('total'))
        }
      })
    }

    let items = this.filterRankingItems(ranking, filter)

    filter.addCountryFlag = eventDiscipline.clubSymbols === 'flags'

    items = map(items, item => this.assembleRankingItem(ranking, item, rankingExercises, filter))

    let title = category.name + ' - ' + this.getTitle(ranking, category, round);
    switch (filter.awards) {
      case 'awards':
        title += ' ' + Vue.i18n.t('awards')
        break
      case 'medals':
        title += ' ' + Vue.i18n.t('displayType.medalWinners')
    }
    if (filter.subDivision) {
      title += ' ' + Vue.i18n.t('subDivision') + ' ' + filter.subDivision;
    }

    let rankingClass = 'u-100px';
    switch (discipline.report) {
      case 'wide':
        rankingClass = 'u-150px';
        break;
      case 'narrow':
        rankingClass = 'u-80px';
        break;
      case 'tiny':
        rankingClass = 'u-50px tiny';
        break;
    }

    let type = filter.awards ? 'awards' : this.hasTeam(ranking) ? 'teamRanking' : 'ranking'
    if (filter.judgeScores) type = 'rankingScores'

    let addExerciseLabel = ranking.rankingType === 'AA'

    return {
      title,
      type,
      data: {
        exercises,
        items,
        tiny: discipline.report === 'tiny',
        addExerciseLabel,
        rankingClass,
        settings: {
          awards: filter.awards,
          showRank: ! (filter.subDivision || filter.awards || filter.clubId),
          addTotal: addExerciseLabel || addTotal
        }
      }
    }
  },

  assembleCertificateItem: function(rankingItem, discipline, filter, multiple = false) {
    const participation = participantLib.getParticipation(rankingItem.participationId)
    const participant = participantLib.getParticipant(participation)
    const club = participantLib.getClub(participant)

    const names = participation.participantType === 'clubMember' ? null :
      participantLib.getParticipantNames(participation)

    const exercises = map(rankingItem.exercises, item => {
      let exercise = clone(item)
      exercise.name = Vue.i18n.t('exercise.type.' + exercise.exerciseTypeId)
      return exercise
    })

    const result = {
      award: filter.awards ? rankingItem.award.label : null,
    }

    if (filter.includeResult) {
      result.ranking = rankingItem.ranking
      result.total = rankingItem.result
    }

    let data = {
      participant: {
        name: participantLib.getName(participation),
        club: club.name,
      },
      teamMembers: names,
      result,
      exercises: exercises,
    }

    if (participation.participantType === 'clubMember') {
      data.participant.firstName = participant.firstname
      data.participant.lastName = participant.lastName
    }

    let items = [data]
    if (multiple) {
      items = names.map(() => data)
    }

    return items
  },

  findCategorySession: function(category, round) {
    let sessions = store.state.sessions.items.filter(s => {
      if (s.roundIndex !== round.index) return false

      const sc = s.categories.find(c => c.categoryId === category.id)
      return !! sc
    })

    if (! sessions?.length) return null

    sessions = sortBy(sessions, 'date').reverse()
    return sessions[0]
  },

  prepareCertificates: function(ranking, category, round, discipline, filter, multiple = false) {
    filter.includeResult = ! filter.awards || category.rankingPublication === 'full'

    let items = this.filterRankingItems(ranking, filter)
    items = map(items, item => {
      return this.assembleCertificateItem(item, discipline, filter, multiple)
    })
    items = concat(...items)

    let subTitle = Vue.i18n.t('certificate.subTitle') + ' ' + category.name
    subTitle += ' ' + this.getTitle(ranking, category, round)

    const session = this.findCategorySession(category, round)

    return {
      subTitle: subTitle,
      category: category.name,
      sessionDate: session ? moment(session.date.substring(0, 10), 'YYYY/MM/DD').format('DD/MM/YYYY') : '',
      awards: filter.awards,
      result: filter.includeResult,
      pages: items,
    };
  },
};

