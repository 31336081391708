<template>
  <input class="c-scores__value" v-focus="editing" @focus="selectValue()" @blur="unselectValue()" ref="input"
         :value="inputValue" readonly="readonly" :tabindex="derived ? -1 : tabIndex"
         :class="{element: value.properties.element !== 'total', judge: value.properties.position >= 0,
                  'is-editing': editing, 'is-skipped': skipped, 'is-counted': counted,
                   'is-confirmed': isConfirmed(), 'is-derived': derived}"/>
</template>

<script>
  const focus = require('vue-focus')

  import numeral from 'numeral'
  const scoreHandler = require('lib/scoreHandler.js')

  import find from 'lodash/find'

  export default {
    directives: { focus: focus },
    name: "scoreValue",
    data: function() {
      return {
        format: undefined,
        derived: false,
        scoreValue: undefined,
        inputValue: null
      }
    },
    props: ["value", "editing", "editInput", "exerciseId", "tabIndex", 'derivedOverRule'],
    computed: {
      exercise: function() {
        if (this.exerciseId) {
          return find(this.$store.state.exercises.items, item => item.id === this.exerciseId)
        }
        return this.$store.state.exercise.exercise
      },
      valueType: function() {
        return this.value.valueType
      },
      skipped: function() {
        return ! this.editing && this.scoreValue?.counted === -1
      },
      counted: function() {
        return ! this.editing && this.scoreValue?.counted === 1
      },
    },
    created: function() {
      this.scoreValue = scoreHandler.exerciseGetValue(this.exercise, this.value.properties)
      this.format = scoreHandler.valueTypeFormat(this.valueType)
      this.derived = this.valueType.inputType === 'derived'
      this.setValue()
    },
    mounted: function() {
      if (this.editing) {
        this.$refs.input.focus()
      }
    },
    watch: {
      exercise: function() {
        this.updateScoreValue()
      },
      value: function() {
        this.updateScoreValue()
      },
      editing: function() {
        this.setValue()
      },
      editInput: function() {
        this.setValue()
      },
    },
    methods: {
      updateScoreValue: function() {
        this.scoreValue = scoreHandler.exerciseGetValue(this.exercise, this.value.properties)
        this.setValue()
      },
      selectValue: function() {
        if (this.derived) {
          return
        }
        this.$refs.input.focus();
        this.$emit('selectValue', {value: this.value, index: this.tabIndex})
      },
      unselectValue: function() {
        if (this.derived) {
          return;
        }
        this.$emit('unselectValue', {value: this.value, index: this.tabIndex})
      },
      setValue: function() {
        this.inputValue = this.editing && this.editInput !== '' ? this.editInput : this.getEditValue()
      },
      getEditValue: function() {
        if (this.scoreValue) {
          if (this.scoreValue.editValue < 0) return '-'

          return numeral(this.scoreValue.editValue).format(this.format)
        }
        return ''
      },
      isConfirmed: function() {
        if (this.scoreValue) {
          return this.scoreValue.confirmed
        }
        return ''
      },
    },
  };

</script>

<style scoped>
</style>
