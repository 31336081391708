<template>
  <form action="#" class='c-form c-form--footer-within'>
    <div class="o-layout">
      <div class="o-layout__item u-1-of-2-at-medium">
        <sceTextField v-model="name" name="name" label="name" v-validate data-vv-rules="required"
                      :class="{'is-invalid': errors.has('name')}"/>
      </div>
      <div class="o-layout__item u-1-of-2-at-medium">
        <sceSelect v-model.number="roundIndex" :options="rounds" name="round" label="round" :config="{localized: false}"
                   v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('round')}"/>
      </div>
      <div class="o-layout__item u-1-of-2-at-medium">
        <div class="c-input p-date-picker p-date-picker-right" :class="{'is-invalid': errors.has('date-mirror')}">
          <label class="c-input__label" for="date">{{$t('date')}}</label>
          <HotelDatePicker format="DD - MMM - YYYY" id="date" singleDaySelection :firstDayOfWeek="1"
                           :startDate="new Date(event.date_from)" :endDate="new Date(event.date_to)"
                           :startingDateValue="date" :showYear="true" :i18n="$options.labelsDatePicker" :minNights="0"
                           @check-in-changed="updateDate($event)"
          />
        </div>
      </div>
      <div class="o-layout__item u-1-of-2-at-medium">
        <div class="c-input" :class="{'is-invalid': errors.has('externalId')}">
          <label class="c-input__label" for="externalId">{{$t('externalId')}}</label>
          <input v-model="externalId" class="c-input__field" name="externalId" type="text" id="externalId" />
        </div>
      </div>
      <div class="o-layout__item">
        <div class="c-input" :class="{'is-invalid': errors.has('notes')}">
          <label class="c-input__label" for="notes">{{$t('notes')}}</label>
          <tiny-editor v-model="description" id="notes" name="notes"></tiny-editor>
        </div>
      </div>
    </div>

    <div class="o-layout">
      <div class="o-layout__item">
        <h3 class="c-title c-title--page-section">{{$t('session.setup')}}</h3>
      </div>
      <div class="o-layout__item u-1-of-2-at-medium"
           :class="orderRotationType !== 'rotation' ? 'u-1-of-3-at-large': 'u-1-of-2-at-large'">
        <sceNumberField v-model="sets" name="sets" min="1" max="20" step="1"
                        :label="orderRotationType === 'rotation' ? 'session.sets' : 'session.setsPanels'"
                        v-validate data-vv-rules="required|numeric|min_value:1|max_value:20"
                        :class="{'is-invalid': errors.has('sets')}" />

        <div class="o-layout" v-if="orderRotationType !== 'mixed'">
          <div class="o-layout__item u-1-of-2-at-medium"
               v-for="setProp in setProperties" v-bind:key="setProp.set">
            <sceNumberField v-model.number="setProp.rotations" :name="'setProp'+setProp.set" min="1" max="20" step="1"
                            :label="$t('session.rotations') + ' - ' + $t(setLabel) + ' ' + setProp.set"
                            v-validate data-vv-rules="required|numeric|min_value:1|max_value:20"
                            :class="{'is-invalid': errors.has('setProp'+setProp.set)}"/>
          </div>
        </div>
      </div>

      <div class="o-layout__item u-1-of-2-at-medium u-1-of-3-at-large" v-if="orderRotationType === 'mixed'">
        <sceNumberField v-model="rotations" name="rotations" label="session.rotations"
                        min="1" max="20" step="1" v-validate data-vv-rules="required|numeric|min_value:0|max_value:20"
                        :class="{'is-invalid': errors.has('rotations')}"/>
      </div>
      <div class="o-layout__item" :class="orderRotationType !== 'rotation' ? 'u-1-of-3-at-large': 'u-1-of-2-at-medium'">
        <div class="o-layout">
          <div class="o-layout__item" v-if="discipline.rotationType === 'rotation'">
            <sceSelect v-model="rotationType" name="rotationType"
                       label="session.rotationType" :options="$options.rotationTypes"
                       v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('rotationType')}" />
          </div>
          <div class="o-layout__item" v-if="orderRotationType !== 'mixed'">
            <sceCheckBox v-model="rotationFree" name="rotationFree" label="session.rotationFree" />
          </div>
          <div class="o-layout__item" v-if="discipline.config.allowPanelSwitch">
            <sceCheckBox v-model="switchPanels" name="switchPanels" label="session.switchPanels" />
          </div>
        </div>
      </div>

      <div class="o-layout__item u-1-of-2-at-medium" v-if="discipline.config.rotationAlignment">
        <sceNumberField v-model.number="skipRotations" :name="skipRotations" min="0" max="20" step="1"
                        label="session.skipRotations"
                        v-validate data-vv-rules="numeric|min_value:0|max_value:20"
                        :class="{'is-invalid': errors.has('skipRotations')}"/>
      </div>

      <div class="o-layout__item u-1-of-2-at-medium">
        <sceSelect v-model="warmup" name="warmup" label="session.warmupConfig" :options="warmupOptions"
                   :config="{optionId: 'name', emptyLabel: 'none', localized: false}" />
      </div>
      <div class="o-layout__item u-1-of-2-at-medium" v-if="roundIndex !== undefined">
        <sceMultiSelect v-model="exerciseTypesSelected" :options="exerciseTypesUsed" name="exercises"
                        inputName="exercisesField" placeholder="select.allExercises" label="session.exercises" />
      </div>
    </div>

    <div class="o-layout u-margin-top-medium">
      <template v-if="rankingTypes.length">
        <div class="o-layout__item" >
          <h3 class="c-title c-title--page-section">{{$t('session.rankings')}}</h3>
        </div>
        <div class="o-layout__item u-1-of-2-at-small" v-for="rankingType in rankingTypes"
             :key="rankingType.rankingType">
          <h4 class="c-title c-title--page-section">{{$t('rankingType.' + rankingType.rankingType)}}</h4>

          <div class="o-layout">
            <div class="o-layout__item">
              <sceCheckBox v-model="rankingType.regular" :name="rankingType.rankingType+'-regular'"
                           label="rankingType.regular" />
            </div>
            <div class="o-layout__item">
              <sceCheckBox v-model="rankingType.open" :name="rankingType.rankingType+'-open'"
                           label="rankingType.open" />
            </div>
            <template  v-if="country && country.regions.length">
              <div class="o-layout__item">
                <sceCheckBox v-model="rankingType.regional" :name="rankingType.rankingType+'-regional'"
                             label="rankingType.regional" />
              </div>
              <div class="o-layout__item">
                <div class="ranking-regions u-margin-left-medium" v-if="rankingType.regional">
                  <sceMultiSelect v-model="rankingType.regions" :options="country.regions" name="regions"
                                  inputName="regionsField" placeholder="select.regions" />
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>

      <div class="o-layout__item u-1-of-2-at-small">
        <h3 class="c-title c-title--page-section">{{$t('session.schedule')}}</h3>

        <table class="o-table o-table--fixed c-table--results">
          <thead>
          <tr>
            <th>{{ $t('label') }}</th>
            <th>{{ $t('time') }}</th>
            <th>
              <button
                class="c-button c-button--secondary c-button--tiny"
                v-on:click.prevent="shiftTimes(-5)">
                <svg role="img" class="c-button__icon" style="fill: #fff;">
                  <use xlink:href="images/svg-symbols.svg#minus-icon"></use>
                </svg>
              </button>
              <button
                class="c-button c-button--primary c-button--tiny"
                v-on:click.prevent="shiftTimes(5)">
                <svg role="img" class="c-button__icon" style="fill: #fff;">
                  <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
                </svg>
              </button>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(timeItem) of timeTable" :key="timeItem.key" v-tooltip="'key: ' + timeItem.key" >
            <td>
              <input v-model="timeItem.label" class="c-input__field" type="text" />
            </td>
            <td>
              <input v-model="timeItem.time" class="c-input__field" type="time" />
            </td>
            <td>
              <button class="c-button c-button--ghost c-button--small c-button--icon"
                      v-on:click.prevent="removeTimeTableItem(timeItem.key)">
                <svg role="img" class="c-button__icon">
                  <use xlink:href="images/svg-symbols.svg#close"></use>
                </svg>
              </button>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <sceButton class="c-button--secondary" label="add" icon="svg-symbols.svg#plus-icon"
                         v-on:click="addTimeTableItem()" />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="o-layout u-margin-top-medium">
      <div class="o-layout__item">
        <h4 class="c-title c-title--page-section">{{$t('categories')}}</h4>
      </div>
      <div class="o-layout__item">
        <table class="o-table o-table--fixed c-table--results">
          <thead>
            <tr>
              <th class="c-table-cell__valign-bottom">
                {{$t('category')}}
              </th>
              <th class="c-table-cell__valign-bottom">
                {{$t('subDivision')}}
              </th>
              <th class="c-table-cell__valign-bottom" v-if="orderRotationType !== 'mixed'">
                {{$t(setLabel)}}
              </th>
              <template v-if="orderRotationType === 'mixed'">
                <th v-for="exerciseType in exerciseTypesUsed" :key="'exercise.type.' + exerciseType.id"
                    class="c-table-cell__valign-bottom">
                  {{$t('exercise.type.' + exerciseType.id)}}
                </th>
              </template>
              <th class="c-table-cell__valign-bottom">
                &nbsp;
              </th>
            </tr>
          </thead>

          <tbody class="c-table__body">
            <tr v-for="sc of sessionCategories" :key="sc.categoryId">
              <td class="c-table__grouped-cells">
                <div class="c-table__cell-content">
                  {{sc.category.name}}
                </div>
              </td>
              <td class="c-table__grouped-cells">
                <div class="c-table__cell-content c-input" :class="{'is-invalid': errors.has('subdiv-'+sc.index)}">
                  <select v-model="sc.subdivision" class="c-input__field c-input__field-select"
                          :name="'subdiv-'+sc.index" v-validate data-vv-rules="required">
                    <option v-for="x in getSubDivisions(sc)" :value="x" :key="x">
                      {{$t('subDivision')}} {{x}}
                    </option>
                  </select>
                </div>
              </td>
              <td class="c-table__grouped-cells" v-if="orderRotationType !== 'mixed'">
                <div class="c-table__cell-content c-input" :class="{'is-invalid': errors.has('set-'+sc.index)}">
                  <select class="c-input__field c-input__field-select" v-model="sc.set"
                          :name="'set-'+sc.index" v-validate data-vv-rules="required">
                    <option v-for="x in getSets()" :value="x" :key="x">
                      {{$t(setLabel)}} {{x}}
                    </option>
                  </select>
                </div>
              </td>
              <template v-if="orderRotationType === 'mixed'">
                <td class="c-table__grouped-cells" v-for="exerciseType in exerciseTypesUsed"
                    :key="sc.categoryId+'-'+exerciseType.id">
                  <div v-if="sc.exercises[exerciseType.id] !== undefined" class="c-table__cell-content c-input"
                       :class="{'is-invalid': errors.has('panel-'+exerciseType.id+'-'+sc.index)}">
                    <select class="c-input__field c-input__field-select" v-validate data-vv-rules="required"
                            v-model="sc.exercises[exerciseType.id]"
                            :name="'panel-'+exerciseType.id+'-'+sc.index">
                      <option :value="0">{{$t('session.doNotInclude')}}</option>
                      <option v-for="x in getSets()" :value="x" :key="x">
                        {{$t('panel')}} {{x}}
                      </option>
                    </select>
                  </div>
                  <div v-else class="c-table__cell-content">
                    &nbsp;
                  </div>
                </td>
              </template>
              <td class="c-table__grouped-cells">
                <div class="c-table__cell-content">
                  <button class="c-button c-button--ghost c-button--small c-button--icon"
                          v-on:click.prevent="removeCategory(sc.category.id)">
                    <svg role="img" class="c-button__icon">
                      <use xlink:href="images/svg-symbols.svg#close"></use>
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="o-layout__item u-1-of-2-at-small">
        <sceSelect v-model="newCategory" name="addCategory" label="session.addCategory"
                   :options="freeCategories" v-on:change="addCategory()"
                   :config="{localized: false, emptyLabel: 'none'}" />
      </div>
    </div>

    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
        <sceButton v-if="id" class="c-button--secondary c-button--large" v-on:click="remove()" label="delete" />
      </div>
      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <sceRouteButton :route="{ name: 'admin.event.discipline.sessions'}" label="cancel"
                        class="c-button--ghost c-button--large" />
        <sceButton class="c-button--primary c-button--large" v-on:click="submit()" label="save" />
      </div>
    </footer>
  </form>
</template>

<script>
  import HotelDatePicker from "vue-hotel-datepicker";
  import tinyEditor from "client/components/tinyEditor";

  import clone from 'lodash/clone';
  import cloneDeep from 'lodash/cloneDeep';
  import forEach from 'lodash/forEach';
  import forOwn from 'lodash/forOwn';
  import filter from 'lodash/filter';
  import find from 'lodash/find';
  import includes from 'lodash/includes';
  import map from 'lodash/map';
  import range from 'lodash/range';
  import sortBy from 'lodash/sortBy';
  import reduce from 'lodash/reduce';
  import moment from 'moment';
  import lang from 'client/lang';

  import categoryLib from 'client/lib/category'
  import planningLib from 'client/lib/planning'
  import rounds from 'client/lib/rounds'
  import sessionLib from 'client/lib/session'
  import options from 'client/lib/options'

  export default {
    name: "session",
    props: ['id'],
    components: {
      HotelDatePicker: HotelDatePicker,
      tinyEditor: tinyEditor,
    },
    ...options.session,
    data: function () {
      return {
        name: '',
        roundIndex: undefined,
        date: undefined,
        externalId: undefined,
        description: undefined,
        sets: 1,
        rotations: 1,
        rotationType: 'fixed',
        rotationFree: false,
        switchPanels: false,
        skipRotations: 0,
        timeTable: map(this.$options.timeTableItems, item => ({
          key: item.id,
          label: this.$t(item.name),
          time: null,
          custom: false,
        })),
        warmup: null,
        sessionCategories: [],
        newCategory: 0,
        exerciseTypesUsed: [],
        country: undefined,
        rankingTypes: [],
        exerciseTypesSelected: [],
        setLabel: 'panel',
        setProperties: [{ set: 1, rotations: 1, id: '0'}],
        freeCategories: [],
      }
    },
    computed: {
      event: function() {
        return this.$store.state.events.current
      },
      session: function () {
        return find(this.$store.state.sessions.items, (item) => {
          return item.id === this.id
        });
      },
      orderRotationType: function() {
        return sessionLib.getSessionRotationTypeFull({
          exerciseTypes: map(this.exerciseTypesSelected, e => e.id)
        })
      },
      rounds: function() {
        return rounds.getRounds().map(r => ({
          id: r.index,
          name: r.name,
        }))
      },
      categories: function() {
        return sortBy(this.$store.state.categories.items, 'index');
      },
      discipline: function() {
        return this.$store.state.eventDiscipline.discipline;
      },
      exerciseTypes: function() {
        return this.$store.state.eventDiscipline.exerciseTypes;
      },
      formats: function() {
        return this.$store.state.eventDiscipline.formats;
      },
      warmupOptions: function() {
        let warmupOptions = [];
        const eventDiscipline =
            find(this.$store.state.eventDisciplines.items,  i => i.id === this.$route.params.disciplineId);
        if (eventDiscipline.planningConfig && eventDiscipline.planningConfig.warmupConfigs) {
          warmupOptions = eventDiscipline.planningConfig.warmupConfigs;
        }
        return warmupOptions;
      },
      round: function() {
        return rounds.getRound(this.roundIndex)
      },
    },
    created: function () {
      console.log(this.$options.timeTableItems)
      this.setLabel = sessionLib.getSetLabel();
      this.setSession();
      this.$options.labelsDatePicker = {
        night: this.$t('day'),
        nights: this.$t('days'),
        'day-names': lang.getDays(),
        'check-in':  this.$t('date.start').trim(),
        'check-out': this.$t('date.end').trim(),
        'month-names': lang.getMonths(),
      };
    },
    watch: {
      sets: function(){
        if (this.orderRotationType === 'mixed') return;
        this.setProperties = this.getSetProperties(this.setProperties);
      },
      session: function() {
        this.setSession();
      },
      roundIndex: {
        immediate: true,
        handler: function() {
          console.log('watch roundIndex')
          this.setRankingTypes()
          this.setFreeCategories()
        },
      },
      setProperties: {
        deep: true,
        handler: function() {
          this.setSessionRotation()
        }
      },
      rotations: function() {
        this.setRotationsTimeTable()
      }
    },
    methods: {
      getSessionRotationType: function() {
        return sessionLib.getSessionRotationType(this.session)
      },
      setSessionRotation: function() {
        if (this.orderRotationType !== 'mixed') {
          let maxSetProp = reduce(this.setProperties, (max, prop) =>
              prop.rotations >= max.rotations ? prop : max,
            {rotations: 1}
          );
          if (this.setProperties.length === this.sets) {
            this.rotations = maxSetProp.rotations;
          } else {
            this.rotations = this.rotations >= maxSetProp.rotations ? this.rotations : maxSetProp.rotations;
          }
        }
      },
      getSetProperties: function(prevSetProperties) {
        return map(Array(Number(this.sets)).fill(), (v,i) => {
          const setNum = i+1;
          const previousSetProp = find(prevSetProperties, setProp => setProp.set === setNum);
          return {
            id: String(i),
            set: setNum,
            rotations: previousSetProp ? previousSetProp.rotations : this.session.rotations
          }
        })
      },
      setSession: function() {
        // this.orderRotationType = this.discipline.rotationType

        if (! this.session) {
          return
        }

        this.name = this.session.name;
        this.description = this.session.description;
        this.roundIndex = this.session.roundIndex
        this.date = this.session.date ? new Date(this.session.date.substr(0,10)) : undefined;
        this.externalId = this.session.accessId;
        this.sets = this.session.sets;
        this.rotations = this.session.rotations;
        this.rotationType = this.session.rotationType;
        this.rotationFree = this.session.rotationFree;
        this.switchPanels = this.session.switchPanels;
        this.skipRotations = this.session.skipRotations;
        this.setProperties = this.getSetProperties(this.session.setProperties);

        if (this.session.timeTable) {
          const timeTable = cloneDeep(this.session.timeTable)

          this.timeTable.forEach(item => {
            const item2 = timeTable.find(i => i.key === item.key)
            if (! item2) {
              timeTable.push(item)
            }
          })
          this.timeTable = timeTable
        }

        this.setRotationsTimeTable()

        let warmup = this.session.warmup;
        const warmupOptions = this.warmupOptions;
        if (warmup) {
          if (! find(warmupOptions, w => w.name === warmup)) {
            warmup = null;
          }
        }
        if (! warmup && warmupOptions.length) {
          warmup = warmupOptions[0].name;
        }
        this.warmup = warmup;

        let sessionCategories = [];

        forEach(this.session.categories, (sessionCategory) => {
          const category = find(this.categories, item => item.id === sessionCategory.categoryId)
          if (! category) return

          const categoryExercises = categoryLib.getCategoryExerciseTypes(sessionCategory.categoryId, this.roundIndex)
          const exercises = {};
          categoryExercises.forEach(ce => {
            let set = 0
            const f = sessionCategory.exercises.find(e => e.exerciseTypeId === ce)
            if (f) set = f.set

            exercises[ce] = set
          })

          sessionCategories.push({
            id: sessionCategory.id,
            category: category,
            set: sessionCategory.set,
            subdivision: sessionCategory.subDivision,
            exercises: exercises,
            regions: sessionCategory.regions,
          });
        });
        this.sessionCategories = sessionCategories;
        this.setExerciseTypesUsed();
        this.exerciseTypesSelected = filter(this.exerciseTypesUsed, item => {
          return includes(this.session.exerciseTypes, item.id);
        });

        const event = this.$store.state.events.current
        if (event.countryId) {
          this.country = find(this.$store.state.events.countries, (item) => {
            return item.id === event.countryId;
          });
        }
        else {
          this.country = undefined;
        }
      },
      setRotationsTimeTable: function() {
        const timeTable = filter(this.timeTable, i => ! i.key.startsWith('rotation-'))

        for (let i = 0; i < this.rotations; i++) {
          let key = 'rotation-warmup-' + i
          let item = this.timeTable.find(i => i.key === key)
          if (!item) {
            item = {
              key,
              label: planningLib.getBlockLabel(this.orderRotationType, this.sets, i, 'warmup'),
              time: null,
              custom: false,
            }
          }
          timeTable.push(item)

          key = 'rotation-start-' + i
          item = this.timeTable.find(i => i.key === key)
          if (!item) {
            item = {
              key,
              label: planningLib.getBlockLabel(this.orderRotationType, this.sets, i, 'start'),
              time: null,
              custom: false,
            }
          }
          timeTable.push(item)

          console.log(this.orderRotationType, this.discipline.config.orderTiming)
          if (this.orderRotationType === 'block' && this.discipline.config.orderTiming) {
            // add time intervals for start of exercises within blocks
            console.log('add exercise start times')
            const exercises = sessionLib.getSessionEffectiveExerciseTypes(this.session)
            console.log(exercises)
            for(let iteration = 0; iteration < exercises.length; iteration++) {
              key = 'rotation-' + i + '-exercise-' + iteration
              item = this.timeTable.find(item => item.key === key)
              if (! item) {
                let label = this.session.rotations > 1 ? this.$t('rotation') + ' ' + (i+1) + ' ' : ''
                label += this.$t('exercise') + ' ' + (iteration+1)
                item = {
                  key,
                  label: label,
                  time: null,
                  custom: false,
                }
              }
              console.log(item)
              timeTable.push(item)
            }
          }
        }

        this.timeTable = sortBy(timeTable, 'time')
      },
      shiftTimes: function(minutes) {
        this.timeTable.forEach(item => {
          if (item.time) {
            item.time = moment(item.time, 'HH:mm').add(minutes, 'm').format('HH:mm')
          }
        })
      },
      setRankingTypes: function() {
        this.rankingTypes = [];
        if (this.round) {
          forEach(this.round.rankings, ranking => {
            if (ranking.enabled) {
              let oldRankingType = null;
              if (this.session) {
                oldRankingType = find(this.session.rankingTypes, item => {
                  return item.rankingType === ranking.rankingType;
                });
              }
              if (oldRankingType) {
                let newRankingType = clone(oldRankingType);
                newRankingType.regions =
                    map(oldRankingType.regions, r => find(this.country.regions, r2 => r2.id === r));
                this.rankingTypes.push(newRankingType);
              }
              else {
                this.rankingTypes.push({
                  rankingType: ranking.rankingType,
                  regular: true,
                  open: false,
                  regional: false,
                  regions: []
                });
              }
            }
          });
        }
      },
      setFreeCategories: function() {
        const selectedIds = map(this.sessionCategories, i => i.category.id)
        this.freeCategories = sortBy(filter(this.categories, item => {
          if (includes(selectedIds, item.id)) {
            return false
          }

          if (! this.round) {
            return false
          }

          const categoryRound = find(item.rounds, categoryRound => categoryRound.roundIndex === this.round.index)
          return categoryRound && categoryRound.formatId
        }), 'index')
      },
      getTime: function(time) {
        return time ? time.substr(11,5) : undefined;
      },
      getSubDivisions: function(sessionCategory) {
        const categoryRound = find(sessionCategory.category.rounds, i => i.roundIndex === this.roundIndex)
        return range(1, parseInt(categoryRound.subDivisions)+1)
      },
      getCategoryFormat: function(category) {
        const categoryRound = find(category.rounds, item => item.roundIndex === this.roundIndex)
        return find(this.formats, i => i.id === categoryRound.formatId)
      },
      getExerciseType: function(id) {
        return find(this.exerciseTypes, i => i.id === id)
      },
      setExerciseTypesUsed: function() {
        let exerciseTypeIds = []
        this.sessionCategories.forEach(sc => {
          const catExerciseTypes = categoryLib.getFormatExerciseTypes(sc.category.id, this.roundIndex)
          exerciseTypeIds = exerciseTypeIds.concat(catExerciseTypes)
        })

        exerciseTypeIds = Array.from(new Set(exerciseTypeIds))

        this.exerciseTypesSelected = this.exerciseTypesSelected.filter(item => {
          return exerciseTypeIds.includes(item.id)
        })
        this.exerciseTypesUsed = map(sortBy(filter(this.exerciseTypes, function(item) {
          return includes(exerciseTypeIds, item.id);
        }),'index'), item => {
          return {id: item.id, name: this.$t('exercise.type.' + item.id)};
        });
      },
      getSets: function() {
        return range(1, parseInt(this.sets) + 1 );
      },
      addTimeTableItem: function() {
        let key_index = -1
        let key = null
        let item = null
        do {
          key = 'custom-' + (++key_index)
          item = this.timeTable.find(i => i.key === key)
        } while (item)

        this.timeTable.push({
          key,
          label: this.$t('label'),
          time: null,
          custom: true,
        })
      },
      removeTimeTableItem: function(key) {
        const item = this.timeTable.find(item => item.key === key)

        if (item?.custom) {
          this.timeTable = this.timeTable.filter(item => item.key !== key)
        } else {
          item.time = null
        }
      },
      addCategory: function() {
        if (this.newCategory !== 0) {
          const category = find(this.categories, i => i.id === this.newCategory)
          const exercises = {}
          const categoryExercises = categoryLib.getCategoryExerciseTypes(this.newCategory, this.roundIndex)
          categoryExercises.forEach(e => exercises[e] = 1)

          this.sessionCategories.push({
            category,
            set: 1,
            subdivision: 1,
            exercises,
          })
          this.newCategory = null

          this.setExerciseTypesUsed()
          this.setFreeCategories()
        }
      },
      removeCategory: function(categoryId) {
        this.sessionCategories = filter(this.sessionCategories, i => i.category.id !== categoryId)
        this.setExerciseTypesUsed()
        this.setFreeCategories()
      },
      updateDate: function(newDate) {
        this.date = newDate;
      },
      submit: function () {
        this.$validator.validateAll().then(() => {
          if (!this.errors.any()) {
            if(this.orderRotationType !== 'mixed'){
              this.setSessionRotation();
            }

            let rankingTypes = cloneDeep(this.rankingTypes);
            forEach(rankingTypes, rt => {
              rt.regions = map(rt.regions, r => r.id);
            });

            const data = {
              id: this.id,
              name: this.name,
              description: this.description,
              roundIndex: this.roundIndex,
              date: moment(this.date).format('YYYY-MM-DD'),
              accessId: this.externalId,
              sets: this.sets,
              rotations: this.rotations,
              rotationType: this.rotationType,
              rotationFree: this.rotationFree,
              switchPanels: this.switchPanels,
              skipRotations: this.skipRotations,
              // schedule: this.schedule,
              timeTable: this.timeTable,
              warmup: this.warmup,
              categories: [],
              rankingTypes: rankingTypes,
              exerciseTypes: map(this.exerciseTypesSelected, e => e.id),
              setProperties: this.setProperties,
            };

            forEach(this.sessionCategories, (sessionCategory, index) => {
              let category = {
                categoryId: sessionCategory.category.id,
                set: sessionCategory.set,
                subDivision: sessionCategory.subdivision,
                exercises: [],
                regions: sessionCategory.regions,
                id: index+1,
              };

              let id2 = 0;
              forOwn(sessionCategory.exercises, function(val, key) {
                category.exercises.push({
                  exerciseTypeId: key,
                  set: val,
                  id: (index+1) + "-" + id2++,
                });
              });
              data.categories.push(category);
            });

            this.$emit('submit', data);
          } else {
            this.$notify.warning('Validation errors');
          }
        });
      },
      remove: function () {
        this.$modal.show({
          title: this.$t('delete.confirm.title'),
          message: this.$t('delete.confirm.text', {item: this.name}),
          onConfirm: () => {
            this.$emit('remove', this.session);
          }
        });
      },
    },
  };
</script>

<style>
</style>
