<template>
  <table ref="ranking" class="c-table js-table u-margin-top-medium">
    <thead>
    <tr>
      <th class="c-table__fixed-x-small-col c-table__first-col" v-if="showRank">{{$t('report.rank')}}</th>
      <th class="c-table__name-row-result">{{$t('participant')}}</th>
      <th v-for="exercise in exercises" :key="exercise">
        {{ exercise }}
      </th>
      <th class="c-exercise__last-col">
        <div class="c-table__fixed-small-col">{{ $t('total') }}</div>
      </th>
      <th class="c-table__empty-last-col"></th>
    </tr>
    </thead>
    <tbody v-for="item in items" :key="item.id">
      <tr class="js-row">
        <td class="c-table__fixed-x-small-col c-table__first-col" v-if="showRank">
          <span class="c-badge" :data-rank="item.ranking"></span>
        </td>
        <td class="c-table__name-row-result js-names">
          <template v-for="name in item.names">
            <span :key="item.id + name" class="c-text-bold">{{ name }}<br/></span>
          </template>
          {{ item.clubName }}<br/>

          <span class="c-text-bold">{{ item.bib }}</span>
        </td>
        <exerciseResult v-for="(exercise, ex_i) in item.exercises" :key="item.id + ex_i" :exercise="exercise"
                        :rankingClass="rankingClass" :tiny="tiny" />
        <td class="c-table__fixed-small-col c-exercise c-exercise__last-col">
          <div class="c-wrapper js-total">
            <template v-if="item.annotations && item.annotations.selection">
              <span class="c-badge" :data-rank="item.annotations.selection"></span>
            </template>
            <template v-if="item.annotations && item.annotations.qualified">
              <span class="c-badge" data-rank="Q"></span>
            </template>
            <template v-if="item.annotations && item.annotations.reserve">
              <span class="c-badge" data-rank="R"></span>
            </template>
            <template v-if="item.annotations && item.annotations.bonus">
              <span class="c-badge">B</span>
            </template>
            <template v-if="item.award">
              <span class="c-points c-total c-total--large">{{ item.award }}</span>
            </template>
            <div class="c-points c-total c-total--large">{{ item.total }}</div>
          </div>
        </td>
        <td class="c-table__empty-last-col"></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import base from './baseTable.vue'

  export default {
    name: 'singleTable',
    extends: base,
    components: {
      'exerciseResult': require('./exerciseResult.vue').default,
    },
    props: ['exercises', 'items', 'rankingClass', 'tiny', 'showRank'],
    watch: {
      items: {
        immediate: true,
        handler: function() {
          // Loop the tables:
          this.$nextTick(() => {
            if (document.getElementsByClassName('js-table')) {
              for (let g = 0; g < document.getElementsByClassName('js-table').length; g++) {
                let heightsNamesArray = [];
                let heightsExercisesArray = [];
                let currentTable = document.getElementsByClassName('js-table')[g];

                for (let i = 0; i < currentTable.getElementsByClassName('js-names').length; i++) {
                  heightsNamesArray.push(currentTable.getElementsByClassName('js-names')[i].getBoundingClientRect().height);
                }

                for (let i = 0; i < currentTable.getElementsByClassName('js-wrapper').length; i++) {
                  heightsExercisesArray.push(currentTable.getElementsByClassName('js-wrapper')[i].getBoundingClientRect().height);
                }

                let highestExercise = Math.max.apply(Math, heightsExercisesArray);

                for (let j = 0; j < currentTable.getElementsByClassName('js-total').length; j++) {
                  currentTable.getElementsByClassName('js-total')[j].style.height = (highestExercise + 1) + 'px';
                }
                for (let k = 0; k < currentTable.getElementsByClassName('c-points-wrapper').length; k++) {
                  currentTable.getElementsByClassName('c-points-wrapper')[k].style.height = highestExercise - 15 + 'px';
                }
              }
            }
          });
        }
      }
    }
  }
</script>

