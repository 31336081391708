<template>
  <div class="c-main">
    <form action="#" class="c-main--flex-1 u-margin-bottom-small">
      <div class="o-layout">
        <div class="o-layout__item">
          <sceTextField v-model="code" name="code" label="code" inputName="code"
                        v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('code')}" />
          <sceTextArea v-model="description" name="description" label="description" inputName="description" rows="2" />

        </div>
      </div>

      <h4 class="c-title c-title--page-section">{{$t('category')}}</h4>
      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-small u-1-of-4-at-medium">
          <sceNumberField v-model.number="category.minAge" name="minAge" label="category.minimalAge"
                          v-validate data-vv-rules="required|numeric|min_value:0|max_value:120"
                          :class="{'is-invalid': errors.has('minAge')}" min="0" max="120" step="1" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small u-1-of-4-at-medium">
          <sceNumberField v-model.number="category.maxAge" name="maxAge" label="category.maximalAge"
                          v-validate data-vv-rules="required|numeric|min_value:0|max_value:120"
                          :class="{'is-invalid': errors.has('maxAge')}" min="0" max="120" step="1" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small u-1-of-4-at-medium">
          <sceSelect v-model="category.gender" name="gender" label="gender" inputName="genderField"
                     :options="$options.genderOptions" v-validate data-vv-rules="required"
                     :class="{'is-invalid': errors.has('gender')}"/>
        </div>
      </div>
      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-small u-1-of-4-at-medium">
          <sceSelect v-model="category.participantType" name="participantType" label="participantType"
                     inputName="participantTypeField" :options="$options.participantTypeOptions"
                     v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('participantType')}"/>
        </div>
        <div class="o-layout__item u-1-of-2-at-small u-1-of-4-at-medium" v-if="category.participantType === 'group'">
          <sceNumberField v-model.number="category.groupSize.min" name="groupSizeMin" label="category.minimalGroupSize"
                          v-validate data-vv-rules="numeric|min_value:0|max_value:100"
                          :class="{'is-invalid': errors.has('groupSizeMin')}" min="0" max="100" step="1" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small u-1-of-4-at-medium" v-if="category.participantType === 'group'">
          <sceNumberField v-model.number="category.groupSize.max" name="groupSizeMiax" label="category.maximalGroupSize"
                          v-validate data-vv-rules="numeric|min_value:0|max_value:100"
                          :class="{'is-invalid': errors.has('groupSizeMax')}" min="0" max="100" step="1" />
        </div>
      </div>
      <div class="o-layout">
        <div class="o-layout__item u-1-of-4-at-small">
          <sceSelect v-model="category.scorePublication" name="scorePublication" label="scorePublication"
                     :options="$options.scorePublicationOptions" v-validate data-vv-rules="required" />
        </div>
        <div class="o-layout__item u-1-of-4-at-small">
          <sceSelect v-model="category.rankingPublication" name="rankingPublication" label="rankingPublication"
                     :options="$options.rankingPublicationOptions" v-validate data-vv-rules="required" />
        </div>
      </div>

      <h4 class="c-title c-title--page-section">{{$t('categoryRounds')}}</h4>
      <div class="c-tabs">
        <div class="c-tab__tab-bar u-margin-bottom-medium">
          <sceButton v-for="catRound of categoryRounds" :key="catRound.round.roundIndex"
                     class="c-button--large c-button--ghost c-tabs__tab-link"
                     :class="{ 'is-active': categoryRound === catRound.round.roundIndex }"
                     v-on:click="categoryRound = catRound.round.roundIndex"
                     :label="$t('round') + ' ' + (catRound.round.roundIndex + 1)"
          />
          <sceButton class="c-button--large c-button--ghost c-tabs__tab-link is-active"
                     icon="svg-symbols.svg#minus-icon" :disabled="categoryRounds.length <= 1"
                     v-on:click="removeCategoryRound" />
          <sceButton class="c-button--large c-button--ghost c-tabs__tab-link is-active"
                     icon="svg-symbols.svg#plus-icon"
                     v-on:click="addCategoryRound" />
        </div>
        <template v-for="catRound of categoryRounds">
          <catRound v-if="catRound.round.roundIndex === categoryRound" :key="catRound.round.roundIndex" v-model="catRound.round" />
        </template>
      </div>

      <h4 class="c-title c-title--page-section">{{$t('session')}}</h4>
      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-small" v-if="discipline.rotationType === 'rotation'">
          <sceSelect v-model="session.rotationType" name="rotationType"
                     label="session.rotationType" :options="$options.rotationTypes"
                     v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('rotationType')}" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small u-padding-top-medium" v-if="discipline.rotationType!=='mixed'">
          <sceCheckBox v-model="session.rotationFree" name="rotationFree" label="session.rotationFree" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small u-padding-top-medium" v-if="discipline.config.allowPanelSwitch">
          <sceCheckBox v-model="session.switchPanels" name="switchPanels" label="session.switchPanels" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceSelect v-model="session.warmup" name="warmup" :config="{ emptyLabel: 'none', localized: false }"
                     label="session.warmupConfig" :options="warmupOptions()" />
        </div>
      </div>

    </form>
    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
        <sceButton v-if="id" class="c-button--secondary c-button--large" v-on:click="remove()" label="delete" />
      </div>

      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <sceRouteButton :route="{ name: 'admin.user.planning.templates'}" label="cancel"
                        class="c-button--ghost c-button--large" />
        <sceButton class="c-button--primary c-button--large" v-on:click="submit()" label="save" />
      </div>
    </footer>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';

import options from 'client/lib/options';
import merge from "lodash/merge"

export default {
  name: 'import-template',
  components: {
    catRound: require('./templateRound').default,
  },
  props: ['id'],
  ...options.category,
  ...options.session,
  data: function() {
    return {
      code: null,
      description: null,
      category: {
        participantType: 'clubMember',
        groupSize: {
          min: 2,
          max: 2,
        },
        minAge: 0,
        maxAge: 120,
        gender: 'mixed',
        scorePublication: 'full',
        rankingPublication: 'full',
      },
      categoryRounds: [
        {
          round: {
            roundIndex: 0
          },
        }
      ],
      categoryRound: 0,
      session: {
        rotationType: 'fixed',
        rotationFree: false,
        switchPanels: false,
        warmup: null,
      },
    }
  },
  computed: {
    importTemplate: function() {
      return find(this.$store.state.importTemplates.items, i => i.id === this.id)
    },
    planningDefault: function () {
      return find(this.$store.state.planningDefaults.items,
        item => item.id === this.$route.params.planningDefaultId)
    },
    discipline: function() {
      return find(this.$store.state.disciplines.items,
        i => i.id === this.planningDefault.disciplineId)
    },
  },
  created: function() {
    if (this.importTemplate) {
      this.code = this.importTemplate.code
      this.description = this.importTemplate.description
      this.category = merge(this.category, cloneDeep(this.importTemplate.data.category))
      this.categoryRounds = this.importTemplate.data.categoryRounds.map(cr => ({
        round: cr,
      }))
      this.session = merge(this.session, cloneDeep(this.importTemplate.data.session))
    }
  },
  watch: {},
  methods: {
    warmupOptions: function() {
      let result = []

      if (this.planningDefault?.config?.warmupConfigs) {
        result = this.planningDefault.config.warmupConfigs.map(wc => ({id: wc.name, name: wc.name}))
      }

      return result
    },
    removeCategoryRound: function () {
      if (this.categoryRounds.length <= 1) return

      if (this.categoryRound === this.categoryRounds.length - 1) {
        this.categoryRound -= 1
      }
      this.categoryRounds.splice(-1, 1)
    },
    addCategoryRound: function() {
      const roundIndex = this.categoryRounds.length
      this.categoryRounds.push({
        round: { roundIndex }
      })
      this.$nextTick(() => {
        this.categoryRound = roundIndex
      })
    },
    submit: function () {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          const data = {
            id: this.id,
            code: this.code,
            description: this.description,
            disciplineId: this.discipline.id,
            owner_id: this.planningDefault.owner_id,
            data: {
              category: this.category,
              categoryRounds: this.categoryRounds.map(cr => cr.round),
              session: this.session,
            },
          }

          this.$emit('submit', data)
        } else {
          this.$notify.warning('Validation errors')
        }
      })
    },
    remove: function () {
      this.$modal.show({
        title: this.$t('delete.confirm.title'),
        message: this.$t('delete.confirm.text', {item: this.code}),
        onConfirm: () => {
          this.$emit('remove', this.id)
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
