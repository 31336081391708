<template>
  <div class="c-main">
    <div class="c-main--flex-1 o-layout">
      <div class="o-layout__item u-1-of-2-at-small u-2-of-3-at-large">
        <div class="o-layout" ref="blocks">
          <div class="o-layout__item u-1-of-2-at-large u-margin-bottom-large c-list"
               v-for="(pBlock, block_index) in prepBlocks" :key="pBlock.block.id">
            <header class="c-list__header">
              <div class="c-list__row u-padding-horizontal-small">
                <button v-if="session.rotationType === 'schedule'"
                        class="c-button c-session-header__button"
                        v-on:click.prevent="perRotation(pBlock.block.id)">
                  Rotation Order
                </button>
                <h2 class="c-list__header-title">
                  {{ getBlockTitle(pBlock.block) }} ({{ pBlock.parts.length }})
                </h2>
              </div>
            </header>

            <div class="c-list__body c-list__body-shadow">
              <draggable v-model="pBlock.parts" :group="{name: 'participation', pull: ['participation'], put: ['participation', 'free', 'filler']}"
                         @start="drag=true" @end="drag=false" @change="changed=true"
                         class="c-session__list" :class="{'has-no-child': pBlock.parts.length === 0}">

                <div v-for="(item,index) in pBlock.parts"
                     :key="(item.filler ? 'filler-' : item.part.id) + '-' + block_index + '-' + index"
                     class="c-list__row c-list__row--draggable o-layout o-layout--flush u-padding-horizontal-medium">
                  <template v-if="item.filler">
                    <div class="c-session-block__bib o-layout__item u-1-of-8">
                    </div>
                    <div class="c-session-block__participant o-layout__item u-7-of-8">
                      <div class="name">
                        {{ $t('session.order.blockFiller') }}
                      </div>
                      <div class="c-session-block__remove-button" :id="'remove-part-button-' + block_index + '-' + index">
                        <button v-on:click.prevent="removeBlockParticipation(block_index, index)"
                                class="c-button c-button--ghost c-button--small c-button--icon">
                          <svg role="img" class="c-button__icon">
                            <use xlink:href="images/svg-symbols.svg#close"></use>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="c-session-block__bib o-layout__item u-1-of-8">
                      <div v-if="item.part.bib" class="">
                        {{ item.part.bib }}
                      </div>
                    </div>
                    <div class="c-session-block__participant o-layout__item u-7-of-8">
                      <component :is="'participant-'+ item.part.participantType" :participation="item.part" :compact="compact"/>
                      <p class="c-session-block__meta-info">
                        <span class="c-session-block__label">{{ $t('category') }}: </span>
                        <span class="c-session-block__value">{{ item.cat.name }}</span>
                      </p>
                      <div class="c-session-block__remove-button" :id="'remove-part-button-' + block_index + '-' + index">
                        <button v-on:click.prevent="removeBlockParticipation(block_index, index)"
                                class="c-button c-button--ghost c-button--small c-button--icon">
                          <svg role="img" class="c-button__icon">
                            <use xlink:href="images/svg-symbols.svg#close"></use>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </template>
                </div>
              </draggable>
            </div>
          </div>
        </div>
      </div>

      <!-- Available Participants -->
      <div class="o-layout__item u-1-of-2-at-small u-1-of-3-at-large">
        <div class="o-layout c-session__participants c-session__available-participants c-list">

          <header class="o-layout__item c-list__header">
            <div class="c-list__row u-padding-horizontal-small">
              <h2 class="c-list__header-title">{{ $t('session.order.availableParticipations') }}
                {{ filteredData.length }} / {{ freeParticipations.length }}</h2>
            </div>
          </header>

          <div class="o-layout__item c-list__body c-session__list-wrapper u-margin-bottom-medium ">
            <draggable v-model="freeFiller"  class="c-session__list"
                       :group="{ name: 'filler', pull: 'clone', put: false }" >
              <div v-for="filler of freeFiller"
                              class="c-list__row c-list__row--draggable o-layout o-layout--flush u-padding-horizontal-medium"
                              :key="filler.filler">
                <div class="c-session-block__bib o-layout__item u-1-of-8">
                  &nbsp;
                </div>
                <div class="c-session-block__participant o-layout__item u-7-of-8">
                  <div class="name">
                    {{ $t('session.order.freeFiller')}}
                  </div>
                </div>
              </div>
            </draggable>
          </div>

          <search-bar :config="$options.searchConfig" v-model="search" class="o-layout__item"/>

          <div class="o-layout__item c-list__body c-session__list-wrapper">
            <p v-if="!freeParticipations.length" class="c-session__no-participants">
              {{ $t('session.order.noAvailableParticipations') }}</p>

            <draggable v-model="filteredData" :group="{ name: 'free', pull: ['participation'], put: false }"
                       class="c-session__list" :class="{'has-no-child': filteredData.length === 0}" @change="freeChange($event)">
              <div v-for="(item) in filteredData"
                   class="c-list__row c-list__row--draggable o-layout o-layout--flush u-padding-horizontal-medium"
                   :key="item.part.id+'-free'" >
                <div class="c-session-block__bib o-layout__item u-1-of-8">
                  <div v-if="item.part.bib" class="">
                    {{ item.part.bib }}
                  </div>
                </div>
                <div class="c-session-block__participant o-layout__item u-7-of-8">
                  <component :is="'participant-'+ item.part.participantType" :participation="item.part" :compact="compact"/>
                  <p class="c-session-block__meta-info">
                    <span class="c-session-block__label">{{ $t('category') }}: </span>
                    <span class="c-session-block__value">{{ item.cat.name }}</span>
                  </p>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-small">
        <button class="c-button c-button--tertiary c-button--large" v-on:click.prevent="print()">
          create PDF
        </button>
        <button class="c-button c-button--tertiary c-button--large" @click.prevent="autoOrderParticipations()">
          {{ $t('planning.selectOrder') }}
        </button>
      </div>

      <div class="o-layout__item u-1-of-2-at-small u-text-right">
        <router-link exact :to="{ name: 'admin.event.discipline.sessions'}"
                     class="c-button c-button--ghost c-button--large">
          {{ $t("cancel") }}
        </router-link>
        <a v-on:click.prevent="submit()" href="#!"
           class="c-button c-button--primary c-button--large">
          {{ $t('save') }}
        </a>
      </div>
    </footer>
  </div>
</template>

<script>
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import orderBy from "lodash/orderBy";
import sortBy from 'lodash/sortBy';

import _block from 'client/lib/block.js'
import { createBlockOrder } from 'client/lib/sortathletes/orderCalc'
import memberLib from '@/lib/member'
import _participant from 'client/lib/participant'
import planningLib from 'client/lib/planning'
import _session from 'client/lib/session'

import draggable from 'vuedraggable'
import numeral from "numeral";

export default {
  name: "session-order-set",
  components: {
    draggable,
    searchBar: require('client/components/searchBarSmall').default,
    "participant-clubMember": require('client/components/models/participantClubMember.vue').default,
    "participant-group": require('client/components/models/participantGroup.vue').default,
  },
  props: ['compact'],
  searchConfig: {
    searchFields: ['bib', 'names', 'club', 'region', 'category'],
    sortFields: ['bib', 'club', 'category', 'region'],
    defaultSortField: 'bib',
  },
  data: function () {
    return {
      totalParts: 0,
      freeParticipations: [],
      filteredData: [],
      prepBlocks: [],
      changed: false,
      skipSet: false,
      export: {},
      freeFiller: [
        {
          filler: true
        }
      ],
      search: {
        searchString: '',
        sortOrder: 'bib',
        sortDir: 'asc',
      },
    };
  },
  computed: {
    session: function () {
      const sessionId = this.$route.params.sessionId;
      return find(this.$store.state.sessions.items, item => {
        return item.id === sessionId;
      });
    },
    blocks: function () {
      const sessionId = this.$route.params.sessionId;
      return filter(this.$store.state.blocks.items, item => {
        return item.sessionId === sessionId;
      });
    },
    blockParticipations: function () {
      return this.$store.state.blockParticipations.items;
    },
    discipline: function () {
      return this.$store.state.eventDiscipline.discipline;
    },
    set: function () {
      return parseInt(this.$route.params.set);
    },
  },
  created: function () {
    this.initComponent();
  },
  watch: {
    set: function () {
      this.initComponent();
    },
    freeParticipations: function() {
      this.filterData()
    },
    search: function() {
      this.filterData()
    },
  },
  beforeRouteLeave: function (to, from, next) {
    this.preventLeave(next);
  },
  beforeRouteUpdate: function (to, from, next) {
    this.preventLeave(next);
  },
  methods: {
    preventLeave: function (next) {
      if (!this.changed) {
        next();
        return;
      }

      this.$modal.show({
        title: this.$t('unsaved_changes.title'),
        message: this.$t('unsaved_changes.message'),
        onConfirm: () => {
          next();
        },
        onCancel: () => {
          next(false);
        }
      });
    },
    getBlockTitle: function (block) {
      if (typeof block !== 'undefined') {
        return _block.getBlockTitle(block, {full: this.skipSet, addExerciseType: true})
      } else {
        return ''
      }
    },
    initComponent: function () {
      this.skipSet = this.discipline.rotationType === 'mixed' || this.session.rotationFree
      this.setFreeParticipations()
      this.prepareBlocks()
      this.filterData()
    },
    setFreeParticipations: function () {
      const set = this.skipSet ? 0 : this.set
      const participations = _session.getParticipations(this.session, set)
      console.log('free participations', participations.length)

      this.freeParticipations = map(participations, part => this.createItem(part))
    },
    createItem: function(part) {
      const cat = _participant.getCategory(part)
      const participant = _participant.getParticipant(part)
      const club = _participant.getClub(participant)
      const names = _participant.getParticipantNames(part)
      return {
        part,
        cat,
        data: {
          bib: part.bib,
          names,
          club: club.name,
          region: memberLib.getClubRegion(club)?.name,
          category: cat.name,
        }
      }
    },
    filterData: function() {
      let filteredData = this.freeParticipations
      if (this.search.searchString.length) {
        const string = this.search.searchString.toLowerCase();
        filteredData = filter(filteredData, item => {
          const searchFields = this.$options.searchConfig.searchFields;
          const match = find(searchFields, searchField => {
            let value = item.data[searchField];
            if (!value) {
              return false;
            }
            if (Array.isArray(value)) {
              const res = find(value, valItem => {
                return valItem && valItem.toLowerCase().indexOf(string) >= 0;
              });
              return res !== undefined;
            } else if (typeof value == 'number') {
              value = numeral(value).format('0')
            }
            return value && value.toLowerCase().indexOf(string) >= 0;
          });

          return match !== undefined;
        })
      }

      if (this.search.sortOrder) {
        const sort = 'data.' + this.search.sortOrder
        console.log('sort by', sort)
        filteredData = orderBy(filteredData, sort, this.search.sortDir)
      }

      this.filteredData = filteredData
    },
    freeChange: function(evt) {
      console.log('free changed', evt)
      if (evt.removed) {
        this.freeParticipations = this.freeParticipations.filter(i => i.part.id !== evt.removed.element.part.id)
      }
    },
    getBlockParticipations: function (block) {
      let blockParticipations = filter(this.blockParticipations, item => {
        return item.blockId === block.id;
      });

      return sortBy(blockParticipations, 'index');
    },
    prepareBlocks: function () {
      let blocks = sortBy(filter(this.blocks, item => {
        return this.skipSet || item.set === this.set;
      }), ['index', 'set']);

      this.prepBlocks = map(blocks, block => {
        const parts = map(this.getBlockParticipations(block), item => {
          if (item.filler) return item

          const part = _participant.getParticipation(item.participationId)
          if (! part) return { filler: true}
          return part
        })
        return this.prepareBlock(block, parts)
      });

      this.$nextTick(() => {
        this.changed = false;
      });
    },
    prepareBlock: function(block, participations) {
      const parts = participations.map(part => {
        if (part.filler) {
          return {
            filler: true
          }
        }
        this.freeParticipations = filter(this.freeParticipations, fPart => {
          return fPart.part.id !== part.id;
        })

        return this.createItem(part)
      })

      return {
        block: block,
        parts: parts
      }
    },
    removeBlockParticipation: function (block_index, part_index) {
      console.log('remove bp')
      const block = this.prepBlocks[block_index];

      const parts = block.parts.splice(part_index, 1);
      console.log(parts)
      if (! parts[0].filler) {
        this.freeParticipations.push(parts[0]);
      }
      this.changed = true;
    },
    submit: function () {
      this.$notify.saveAsync(
        this.$t('order'),
        this.$store.dispatch('blocks.saveOrders', {prepBlocks: this.prepBlocks, sessionId: this.session.id}),
        () => {
          this.changed = false;
        }
      );
    },
    perRotation: function (blockId) {
      // TODO: check if saved

      this.$navigation.goto({
        name: 'admin.event.discipline.sessions.order.rotations',
        params: {sessionId: this.$route.params.sessionId, blockId: blockId}
      });
    },
    print: function () {
      const eventDiscipline = find(this.$store.state.eventDisciplines.items, (item) => {
        return item.id === this.session.eventDisciplineId
      })

      planningLib.printStartingOrder(eventDiscipline, [this.session])
    },
    autoOrderParticipations: async function () {
      const eventDiscipline = find(this.$store.state.eventDisciplines.items, (item) => {
        return item.id === this.session.eventDisciplineId
      });
      const config = eventDiscipline.planningConfig
      if (config.checkOverlaps) {
        await this.$store.dispatch('clubSchedule.get', eventDiscipline.eventId)
      }

      createBlockOrder(eventDiscipline.planningConfig, this.session, this.set).then(result => {
        console.log('order')
        console.log(result)

        this.setFreeParticipations()
        this.prepBlocks = sortBy(map(result.blocks, block => this.prepareBlock(block.block, block.participations)),
          ['block.index', 'block.set']);
        this.changed = true;
      })
    }
  },
};
</script>

<style scoped>
</style>
